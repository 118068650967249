<template>
  <div class="contact">
    <div class="contact__image-wrapper">
      <img
        v-if="image"
        :src="image"
        class="contact__image"
      >
    </div>
    <div class="contact__info">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppContact',
  props: {
    image: String
  }
}
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__info {
    margin-top: 30px;
  }
}
</style>
