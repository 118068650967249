<template>
  <main class="about">
    <section class="preview">
      <div class="container">
        <div class="preview__info">
          <h2 class="preview__title">О компании</h2>
          <p class="preview__text">
            СТЕЙЛ-ЮГ – динамично развивающийся провайдер облачных сервисов на базе собственных дата-центров, предоставляющий облачные сервисы как для корпоративного, так и для государственного секторов.
            <br><br>
            Мы предлагаем комплексные технологические решения и услуги для разных уровней бизнеса и проектов, которые позволяют удовлетворить все ИТ-потребности: серверы, сервисы и сети.
            <br><br>
            Нашим партнером является крупный региональный Интернет-провайдер (АО «Электросвязь») который предоставляет сеть передачи данных с резервными каналами, что позволяет нам предоставить любой сервис до заказчика по единым линиям связи с минимальными задержками и эффективно доставлять контент до заказчика.
          </p>
        </div>
        <div class="preview__image-wrapper">
          <img
            src="/images/logo.svg"
            alt="лого"
            class="preview__image"
          >
        </div>
      </div>
    </section>

    <section class="partners">
      <div class="container">
        <h2 class="partners__title">Наши партнеры</h2>

        <div class="partners__list">
          <div
            v-for="(partner, index) of $store.getters.partners"
            :key="index"
            class="partners__item"
          >
            <img :src="partner">
          </div>
        </div>
      </div>
    </section>

    <section class="requisites">
      <div class="container">
        <h2 class="requisites__title">Реквизиты</h2>

        <div class="requisites__list">

          <div class="row">
            <p class="row__key">
              Полное наименование организации:
            </p>
            <p class="row__value">
              Общество с ограниченной ответственностью "СТЕЙЛ-ЮГ"
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Генеральный директор:
            </p>
            <p class="row__value">
              Гуляев Евгений Евгеньевич
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Юридический адрес:
            </p>
            <p class="row__value">
              115230, Москва г, Каширское ш, дом № 3, корпус 2, строение 9
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Почтовый адрес:
            </p>
            <p class="row__value">
              115230, Москва г, Каширское ш, дом № 3, корпус 2, строение 9
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Расчетный счет:
            </p>
            <p class="row__value">
              40702810508000007618
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Банк:
            </p>
            <p class="row__value">
              СТАВРОПОЛЬСКИЙ Ф-Л ПАО "ПРОМСВЯЗЬБАНК"
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              БИК:
            </p>
            <p class="row__value">
              040702773
            </p>
          </div>

          <div class="row">
            <p class="row__key">
              Корр. счет:
            </p>
            <p class="row__value">
              30101810500000000773
            </p>
          </div>

          <div class="row">
            <p class="row__key">
<!--              Электронная почта: <span style="color: #0019FF">Cloudbit@mail.ru</span>-->
              Телефон: +7 (495) 2695014
            </p>
            <p class="row__value row__value-items">
              <span>ИНН: <b>7701584501</b></span>
              <span>КПП: <b>772401001</b></span>
              <span>ОГРН: <b>1057746300732</b></span>
              <span>ОКПО: <b>76545452</b></span>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="location">
      <div class="container">
        <h2>Месторасположение</h2>

        <div class="location__content">
          <div class="info">
            <div
              v-for="(location, index) of locations"
              :key="index"
              class="info-item"
            >
              <div>
                <BaseIcon icon="location-arrow" width="24px" />
              </div>

              <div class="info-item__content">
                <p
                  class="info-item__title"
                  @click="setActiveMap(location.id)"
                >
                  {{ location.title }}
                </p>
                <p class="info-item__postscript">{{ location.postscript }}</p>
              </div>
            </div>
          </div>
          <div class="map">
            <YandexMap
              :points="yandexMapOptions.coords"
              :center="yandexMapOptions.center"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="contacts">
      <div class="container">
        <h2>Контактная информация</h2>

        <div class="contacts__list">
          <AppContact
            class="contacts__item"
            image="/images/arms.svg"
          >
            <p>
              По вопросам партнерства: <span style="color: #0019FF">priemnaya@stl-u.ru</span>
            </p>
          </AppContact>
          <AppContact
            class="contacts__item"
            image="/images/circle-wtf.svg"
          >
            <p>
              Техническая поддержка: <span style="color: #0019FF">support@stl-u.ru</span>
            </p>
          </AppContact>
          <AppContact
            class="contacts__item"
            image="/images/phone-ct.svg"
          >
            <p>
              Телефон технической поддержки:<br><b>+7 (495) 2695014<br>Работает круглосуточно</b>
            </p>
          </AppContact>
        </div>
      </div>
    </section>

    <section class="request">
      <div class="container">
        <h2>Свяжитесь с нами</h2>

        <div class="request-inputs">
          <BaseInput
            v-model="form.surname"
            placeholder="Фамилия"
            class="request-inputs__input request-inputs__input-surname"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.surname.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseInput>
          <BaseInput
            v-model="form.name"
            placeholder="Имя"
            class="request-inputs__input request-inputs__input-name"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.name.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseInput>
          <BaseInput
            v-model="form.patron"
            placeholder="Отчество"
            class="request-inputs__input request-inputs__input-patron"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.patron.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseInput>
          <BaseInput
            v-model="form.company"
            placeholder="Наименование компании"
            class="request-inputs__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.company.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseInput>
          <BaseInput
            v-model="form.mail"
            placeholder="Введите ваш email"
            class="request-inputs__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.mail.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
            <BaseError v-if="v$.form.$dirty && v$.form.mail.email.$invalid">
              {{ $store.state.vuelidate.email }}
            </BaseError>
          </BaseInput>
          <BaseInput
            v-model="form.phone"
            placeholder="Контактный телефонный номер:"
            class="request-inputs__input"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.phone.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseInput>
          <div class="request-inputs__cb">
            <BaseCheckbox
              v-model="form.checkbox"
              textSize="sm"
              label="Я даю согласие на сбор и обработку персональных данных и соглащаюсь с Политикой сбора и обработки персональных данных"
            >
              <template #error>
                <BaseError v-if="v$.form.$dirty && v$.form.checkbox.checked.$invalid">
                  {{ $store.state.vuelidate.required }}
                </BaseError>
              </template>
            </BaseCheckbox>

            <AppCaptcha style="margin-top: 14px;" />
          </div>

          <BaseTextarea
            v-model="form.comment"
            placeholder="Комментарий к заявке"
            class="request-inputs__tarea"
          >
            <BaseError v-if="v$.form.$dirty && v$.form.comment.required.$invalid">
              {{ $store.state.vuelidate.required }}
            </BaseError>
          </BaseTextarea>
        </div>

        <BaseButton
          class="request__btn"
          @click="onSubmit"
        >
          Отправить заявку
        </BaseButton>
      </div>
    </section>
  </main>

  <ModalThank ref="modalThank" />
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import AppContact from '@/components/AppContact'
import BaseIcon from '@/components/Base/BaseIcon'
import BaseError from '@/components/Base/BaseError'
import BaseInput from '@/components/Base/BaseInput'
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import BaseButton from '@/components/Base/BaseButton'
import BaseTextarea from '@/components/Base/BaseTextarea'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import YandexMap from '@/components/YandexMap'
import AppCaptcha from '@/components/AppCaptcha'

export default {
  name: 'PageAbout',
  components: {
    AppCaptcha,
    YandexMap,
    ModalThank,
    AppContact,
    BaseIcon,
    BaseError,
    BaseInput,
    BaseCheckbox,
    BaseButton,
    BaseTextarea
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      locations: [
        {
          id: 1,
          title: 'г. Махачкала, пр. И.Шамиля, 2 «В»',
          postscript: 'ПН-ВС: 09:00 – 18:00',
          coords: { lat: 42.982618, lng: 47.469702 },
          active: true
        },
        {
          id: 2,
          title: 'г. Москва, площадь Владимира, дом 3, корпус 2',
          postscript: 'ПН-ВС: 09:00 – 18:00',
          coords: { lat: 55.730907, lng: 37.60284 },
          active: false
        }
      ],
      form: {
        surname: '',
        name: '',
        patron: '',
        company: '',
        mail: '',
        phone: '',
        comment: '',
        checkbox: true
      }
    }
  },
  computed: {
    yandexMapOptions () {
      const current = this.locations.find(item => item.active)
      return {
        coords: this.locations.map(item => {
          const { lat, lng } = item.coords
          return [lat, lng]
        }),
        center: [current.coords.lat, current.coords.lng]
      }
    }
  },
  methods: {
    setActiveMap (id) {
      this.locations.forEach(item => { item.active = false })

      const current = this.locations.find(item => item.id === id)
      current.active = true
    },
    async onSubmit () {
      if (this.v$.form.$invalid) {
        this.v$.form.$touch()
        return
      }

      try {
        await this.$api.common.sendClientData({
          surname: this.form.surname,
          name: this.form.name,
          patronymic: this.form.patron,
          companyName: this.form.companyName,
          email: this.form.mail,
          phone: this.form.phone,
          description: this.form.comment
        })

        this.form.surname = ''
        this.form.name = ''
        this.form.patron = ''
        this.form.company = ''
        this.form.mail = ''
        this.form.phone = ''
        this.form.comment = ''
        this.form.checkbox = true

        this.$refs.modalThank.open()
        this.v$.form.$reset()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  },
  validations: {
    form: {
      surname: {
        required
      },
      name: {
        required
      },
      patron: {
        required
      },
      company: {
        required
      },
      mail: {
        required,
        email
      },
      phone: {
        required
      },
      comment: {
        required
      },
      checkbox: {
        checked: val => !!val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  .preview,
  .partners,
  .requisites,
  .location,
  .contacts,
  .request {
    padding: 70px 0;

    @media screen and (max-width: $media-md) {
      padding: 30px 0;
    }
  }

  .preview {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__info {
      max-width: 63%;
      @media screen and (max-width: $media-md) {
        max-width: 100%;
      }
    }
    &__title {}
    &__text {
      margin-top: 40px;
      font-size: 14px;
      line-height: 1.4;
    }
    &__image-wrapper {
      @media screen and (max-width: $media-md) {
        display: none;
      }

      img {
        @media screen and (max-width: $media-lg) {
          width: 280px;
        }
      }
    }
    &__image {
      display: block;
      width: 330px;
    }
  }
  .partners {
    &__list {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(5, 220px);
      justify-content: space-between;
      grid-auto-rows: 120px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .requisites {
    &__list {
      margin-top: 40px;

      .row {
        display: grid;
        grid-template-columns: 280px 1fr;
        font-size: 14px;
        grid-column-gap: 140px;

        @media screen and (max-width: $media-lg) {
          grid-template-columns: 1fr;
        }

        &:not(:first-child) {
          margin-top: 25px;
        }

        &__key {
          @media screen and (max-width: $media-lg) {
            font-weight: 600;
            font-size: 14px;
          }
        }

        &__value {
          @media screen and (max-width: $media-lg) {
            margin-top: 12px;
            font-size: 14px;
          }

          &-items {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: $media-esm) {
              display: grid;
              grid-template-columns: 1fr;
              grid-gap: 10px;
              justify-content: initial;
            }
          }
        }
      }
    }
  }
  .location {
    &__content {
      margin-top: 40px;
      display: flex;
      height: 526px;
      box-shadow: 0 0 20px rgba(209, 212, 224, 0.25), 0 0 60px #E4EAF1;
      border-radius: 15px;
      overflow: hidden;

      @media screen and (max-width: $media-md) {
        flex-direction: column-reverse;
        height: 680px;
      }

      @media screen and (max-width: $media-esm) {
        height: 600px;
      }

      .info {
        height: 100%;
        background: #fff;
        width: 37%;
        padding: 40px;

        @media screen and (max-width: $media-md) {
          height: initial;
          width: initial;
        }

        &-item {
          display: flex;

          &:not(:last-child) {
            margin-bottom: 40px;

            @media screen and (max-width: $media-esm) {
              margin-bottom: 20px;
            }
          }

          &__content {
            margin-left: 16px;
          }

          &__title {
            font-weight: 500;
            font-size: 21px;
            line-height: 1.3;
            cursor: pointer;

            @media screen and (max-width: $media-esm) {
              font-size: 16px;
            }

            &:hover {
              color: $secondary-color;
            }
          }

          &__postscript {
            font-size: 14px;
            color: #4D4D4D;
            margin-top: 20px;

            @media screen and (max-width: $media-esm) {
              margin-top: 12px;
              font-size: 12px;
            }
          }
        }
      }
      .map {
        height: 100%;
        flex-grow: 1;
        & > div {
          height: 100%;
        }
      }
    }
  }
  .contacts {
    &__list {
      margin-top: 90px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__item {
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
    }
  }
  .request {
    padding-bottom: 0 !important;
    &-inputs {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 15px;
      margin-top: 40px;

      &__input {
        grid-column: 1/4;
        @media screen and (max-width: $media-md) {
          grid-column: 1/7;
        }

        &-surname {
          grid-column: 1/2;
          @media screen and (max-width: $media-md) {
            grid-column: 1/3;
          }
          @media screen and (max-width: $media-esm) {
            grid-column: 1/7;
          }
        }
        &-name {
          grid-column: 2/3;
          @media screen and (max-width: $media-md) {
            grid-column: 3/5;
          }
          @media screen and (max-width: $media-esm) {
            grid-column: 1/7;
          }
        }
        &-patron {
          grid-column: 3/4;
          @media screen and (max-width: $media-md) {
            grid-column: 5/7;
          }
          @media screen and (max-width: $media-esm) {
            grid-column: 1/7;
          }
        }
      }

      &__tarea {
        grid-row: 1/5;
        grid-column: 4/7;
        @media screen and (max-width: $media-md) {
          grid-column: 1/7;
          grid-row: initial;
          grid-row-start: 5;
          height: 200px;
        }
        @media screen and (max-width: $media-esm) {
          grid-row-start: 7;
        }
      }

      &__cb {
        grid-row: 5/6;
        grid-column: 1/4;
        @media screen and (max-width: $media-md) {
          grid-column: 1/7;
          grid-row: initial;
        }
      }
    }

    &__btn {
      margin-top: 60px;

      @media screen and (max-width: $media-sm) {
        margin-top: 30px;
      }
    }
  }
}
</style>
