<template>
  <div class="textarea">
    <textarea
      v-bind="$attrs"
      :type="type"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :required="required"
      :placeholder="placeholder"
      :name="name"
      :value="modelValue"
      class="textarea__input"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    autocomplete: Boolean,
    autofocus: Boolean,
    disabled: Boolean,
    required: Boolean,
    type: String,
    modelValue: [String, Number],
    name: {
      type: String,
      default: null
    },
    placeholder: String,
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  display: flex;
  flex-direction: column;

  &__input {
    display: block;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    border-radius: 10px;
    background: #F3F5F7;
    border: none;
    height: 100%;
    font-family: inherit;
    resize: none;
    &:focus {
      outline: 0;
    }
  }
}
</style>
