<template>
  <div
    ref="map"
    class="map"
  />
</template>

<script>
let globalMap = null

export default {
  name: 'YandexMap',
  props: {
    points: {
      type: Array,
      default: () => []
    },
    center: {
      type: Object,
      default: () => ({ lat: -31.563944, lng: 146.154355 })
    },
    zoom: {
      type: Number,
      default: 8
    }
  },
  watch: {
    center (value) {
      globalMap.setCenter(value)
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {
    initMap () {
      const ymaps = window.ymaps

      window.ymaps.ready(() => {
        globalMap = new ymaps.Map(this.$refs.map, {
          center: this.center,
          zoom: this.zoom
        })

        const placemarks = this.points.map(item => {
          return new ymaps.Placemark(item)
        })

        placemarks.forEach(placemark => {
          globalMap.geoObjects.add(placemark)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
  width: 100%;
}
</style>
